import './datatables.js';
import { logoutInactive } from '../../utilities/logout.js';

// START SHOELACE CONFIG
import '@shoelace-style/shoelace/cdn/themes/light.css';
import '@shoelace-style/shoelace/cdn/components/icon/icon.js';
import '@shoelace-style/shoelace/cdn/components/alert/alert.js';
import { setBasePath } from '@shoelace-style/shoelace/cdn/utilities/base-path.js';
// Set the base path to the folder with Shoelace assets
setBasePath('/assets/dist/shoelace');
// END SHOELACE CONFIG

import { Toaster } from '../../utilities/toaster.js';
import i18n from '../../utilities/i18n.js';
// import '../../utilities/create-element.js';

Foundation.Abide.defaults.validators['ajaxValidator'] = ajaxValidator;
Foundation.Abide.defaults.validators['intl-tel-input'] = validateIntlTelInput;
Foundation.Abide.defaults.patterns['time-hour'] = /^([01]?[0-9]|2[0-3])$/;
Foundation.Abide.defaults.patterns['time-minutes'] = /^[0-5]?[0-9]$/;
Foundation.Abide.defaults.patterns['telephone'] = /^[\d \(\)]*$/;
Foundation.Abide.defaults.patterns['greater_0'] = /[1-9][0-9]*$/;
Foundation.Abide.defaults.patterns['vrm_basic'] = /^[A-Z\d][A-Z\d\- ]{0,11}[A-Z\d]$/i;
Foundation.Abide.defaults.patterns['amount_greater_0'] = /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/;

$(document).foundation();

var iOSiPadOS;



window.isMobile = (/Mobi|Android/i.test(navigator.userAgent))
	|| (/^iP/.test(navigator.platform) || /^Mac/.test(navigator.platform) && navigator.maxTouchPoints > 4);

$(document).ready(function () {
	iOSiPadOS = /^iP/.test(navigator.platform) || /^Mac/.test(navigator.platform) && navigator.maxTouchPoints > 4;

	// Not on a mobile device and not on apple mobile device
	// if (!(/Mobi|Android/i.test(navigator.userAgent)) && !iOSiPadOS) {
	// ! DO NOT ALTER - SHARED CLASSES FOR GENERAL USE

	const sumoOptionsCore = {
		forceCustomRendering: true,
		renderLi: (li, originalOption) => li.attr('data-id', originalOption.parent().attr('name')+'-'+originalOption.val())
	};
	const sumoOptions = {
		standard: sumoOptionsCore,
		search: $.extend({}, sumoOptionsCore, {search: true}),
		controls: $.extend({}, sumoOptionsCore, {search: true, okCancelInMulti: true}),
	}
	if ($('.sumo-select').length) {
		$('.sumo-select').SumoSelect(sumoOptions.standard);
	}
	if ($('.sumo-select-search').length) {
		$('.sumo-select-search').SumoSelect(sumoOptions.search);
	}
	if ($('.sumo-select-search-controls').length) {
		$('.sumo-select-search-controls').SumoSelect(sumoOptions.controls);
	}
	$('.sumo-select-options').each(function() {
		const bespoke = $(this).data('sumo') ? $(this).data('sumo') : {};
		const options = $.extend({}, sumoOptions.standard, bespoke);
		$(this).SumoSelect(options);
	});
	$('.sumo-select-multiplefilter').each(function() {
		const bespoke = $(this).data('sumo') ? $(this).data('sumo') : {};
		const config = {
			"selectAll":true,
			"triggerChangeCombined":true,
			"okCancelInMulti":true,
			"placeholder":i18n.__('all'),
			"captionFormat":i18n.__('sumoselect.caption.selected'),
			"captionFormatAllSelected":i18n.__('all'),
			"locale":[i18n.__('buttons.apply'), i18n.__('buttons.cancel'), i18n.__('buttons.select.all')]
		};
		const options = $.extend({}, sumoOptions.standard, config, bespoke);
		$(this).SumoSelect(options);
	});

	// Used to create dummy uuids, see common->getDummyUuid()
	$('body').data('loaded', new Date().getTime());

	// Ensure disabled links do nothing
	$('body')
		.on('click tap', 'a.disabled', function (e) {
			e.stopPropagation();
			e.preventDefault();
			return false;
		});

	// Initiates a contacts autocomplete as created by common->getContactsDropdown
	$('input[data-pseudo="contacts"]').each(function(k, v) {

		// Set id
		let selector = $(this).attr('id');
		if (selector == undefined) {
			selector = 'pseudoContacts' + (k + 1);
		}
		$(this).attr('id', selector).addClass('pseudo-contacts');
		selector = '#' + selector;

		// Get contact types to pass to ajax
		let type = $(this).data('type');

		// Are we using server-side or client-side search?
		let serverSide = $(this).data('ajax');

		// Generate dummy select
		let name = $(this).attr('name').replace(/Ac$/, '');
		let uid = name + name.hashCode();
		let $select = $('<select>')
			.attr('name', name)
			.attr('id', uid)
			.css({ visibility: 'hidden' })
			.addClass('SumoUnder');
		$('body').append($select);
		uid = '#' + uid;

		// Server-side vs client-side
		let searchEngine, cache, query
		if (serverSide) {
			query = (input) => { return input; };
			searchEngine = (query, record) => { return record.name; };
			cache = false;
		} else {
			query = (input) => {
				return input
					.replace(/^ +/, '')
					.replace(/ +$/, '')
					.replace(/,/, ' ')
					.replace(/ +/, ' ')
					.toLowerCase();
			};
			searchEngine = (query, record) => {
				let str = [record.firstName, record.lastName, record.firstName].join(' ');
				return str.indexOf(query) != -1 ? record.name : null;
			};
			cache = true;
		}

		// Initiate autoComplete https://tarekraafat.github.io/autoComplete.js
		const contactsComplete = new autoComplete({
			selector: '.pseudo-contacts', //selector,
			threshold: 3,
			debounce: 300,
			searchEngine: searchEngine,
			query: query,
			resultsList: {
				maxResults: 250,
			},
			resultItem: {
				element: (item, data) => {
					item.setAttribute('data-id', 'P-'+data.value.id);
				},
			},
			data: {
				src: async (query) => {
					return await $.post({
						url: '/people/autocomplete',
						data: { search: query, type: type },
						dataType: 'json'
					});
				},
				cache: cache,
			},
			events: {
				input: {
					focus() {
						if (contactsComplete.input.value.length) {
							contactsComplete.start();
						}
					},
					blur() {
						if ($(this).data('selected')) {
							$(this).val($(this).data('selected').name);
						}
						contactsComplete.close();
					},
					selection: (event) => {
						let contact = event.detail.selection.value;
						let $option = $('<option>')
							.val(contact.id)
							.text(contact.name)
							.prop('selected', true);
						$(uid).empty().append($option).change();
						$(this).val(contact.name).data('selected', contact);
					}
				}
			}
		});

		$(this)
			// Handle search clear
			.on('search', function() {
				if ($(this).val().length < 3) {
					let $option = $('<option>')
						.val('')
						.text('empty')
						.prop('selected', true);
					$(uid).empty().append($option).change();
					$(this).data('selected', { name: '' });
				}
			});

	});

	//drop downs
	$('#header ul li.drop').mouseover(function () {
		$(this).find('ul').show();
		$(this).find('a:first').addClass('hover');
		$(this).mouseout(function () {
			$(this).find('ul').hide();
			$(this).find('a:first').removeClass('hover');
		});
	});

	$('#add-new-note').on('click', function (event) {
		event.preventDefault();
		var notes = $('textarea[name="new-note"]').val();
		var table = $(this).data('table');
		var tableId = $(this).data('tableId');
		var loaded = $('body').data('loaded');
		var button = $(this);

		$.ajax({
			url: '/general/addNotes',
			type: 'POST',
			dataType: 'json',
			data: { notes, table, tableId, loaded },

			beforeSend: function (xhr, textStatus) {
				button.prop('disabled', true);
			},
			success: function (data, textStatus, xhr) {
				button.prop('disabled', false);
				if (data.error == false) {
					$('textarea[name="new-note"]').val('');
					getNotes(table, tableId, 1);
					Toaster.success('Note added successfully', 'Add Note');
				} else {
					logoutInactive(data);
					Toaster.error(data.errorMsg, 'Add Note');
				}
			},
			error: function (xhr, textStatus, errorThrown) {
				button.prop('disabled', false);
			}
		});
	});

	$('input[name="addVehicle"]').on('click', function (event) {
		$('.addVehicle').fadeIn('slow');
		$(this).fadeOut('slow');
	});

	$('input[name="saveVehicle"]').on('click', function (event) {

		var rel = $(this).attr('rel');
		var relArr = rel.split('|');
		var tableId = null;
		var button = $(this);

		if (parseFloat(relArr[1])) {
			tableId = relArr[1];
		} else {
			tableId = $('select[name="' + relArr[1] + '"]').val();
		}


		$('#addVehicleErrors').hide();

		$.ajax({
			url: '/general/addVehicle',
			type: 'POST',
			dataType: 'json',
			data: {
				vrm: $('input[name="vrm"]').val(),
				regCountry: $('select[name="regCountry"]').val(),
				make: $('select[name="vehicleMake"]').val(),
				model: $('input[name="model"]').val(),
				colour: $('select[name="vehicleColour"]').val(),
				vehicleType: $('input[name="vehicleType"]:checked').val(),
				table: relArr[0],
				tableId: tableId
			},

			beforeSend: function (xhr, textStatus) {

				//called when complete
				button.val('Please wait...').attr('disabled', true);
			},
			success: function (data, textStatus, xhr) {

				//called when successful
				button.val('Save').attr('disabled', false);

				if (data.error == false) {
					$('input[name="addNotes"]').fadeIn('slow');
					$('.addVehicle').fadeOut('slow');
					$('input[name="vrm"]').val('');
					$('select[name="regCountry"]').val('');
					$('select[name="vehicleMake"]').val('');
					$('input[name="model"]').val('');
					$('select[name="vehicleColour"]').val('');
					$('input[name="vehicleType"]').removeAttr('checked');
					$('#addVehicleErrors').hide();
					$('input[name="addVehicle"]').fadeIn('slow');

					getVehicles(relArr[0], tableId);

				} else {
					logoutInactive(data);
					$('#addVehicleErrors').html(data.errorMsg).fadeIn('slow');
				}

			},
			error: function (xhr, textStatus, errorThrown) {

				//called when there is an error
				button.val('Save').attr('disabled', false);
				$('#addVehicleErrors').html('Error: ' + errorThrown).fadeIn('slow');
			}
		});
	});

	$('#cancelVehicle').on('click', function (event) {
		$('.addVehicle').fadeOut('fast');
		$('#addVehicleErrors').hide();
		$('input[name="addVehicle"]').fadeIn('slow');
	});

	// $('.datepicker').datepicker({
	// 	constrainInput: true,
	// 	dateFormat: 'dd/mm/yy',
	// });

	flatpickr('.todatepicker', { allowInput: true, dateFormat: "d/m/Y" });
	flatpickr('.minTodayDatepicker', { allowInput: true, dateFormat: "d/m/Y", minDate: "today" });
	// $('.minTodayDatepicker').datepicker({
	// 	dateFormat: 'dd/mm/yy',
	// 	minDate: '0'
	// });

	$('.deleteVehicle').on('click', function (event) {
		var rel = $(this).attr('rel');
		var relArr = rel.split('|');

		//prepare data for are you sure dialog box
		//Format: callback, id
		var data = new Array('deleteVehicle', relArr[0], relArr[1], relArr[2]);

		var result = $('#areYouSureDialog').data('data', data).dialog('open');
	});

	$('.hideSearchResult').on('click', function (event) {
		$('#searchResult').fadeOut();
	});

	$('select[name="property"]').on('change', function (event) {

		var propertyId = $(this).val();

		if (propertyId != '') {
			$.ajax({
				url: "/general/getPropertyAddress",
				type: "POST",
				dataType: "json",
				data: { propertyId: propertyId },

				beforeSend: function () {
					//called when complete
				},
				success: function (data) {
					if (data.error == true) {
						logoutInactive(data);
					}
					if (data != null) {
						$('input[name="apartmentNo"]').val(data.apartmentNo);
						$('input[name="addressLine1"]').val(data.addressLine1);
						$('input[name="addressLine2"]').val(data.addressLine2);
						$('input[name="addressLine3"]').val(data.addressLine3);
						$('input[name="addressLine4"]').val(data.addressLine4);
						$('input[name="district"]').val(data.district);
						$('input[name="city"]').val(data.city);
						$('input[name="county"]').val(data.county);
						$('input[name="postCode"]').val(data.postCode);
						$('select[name="country"]').val(data.country);
					}
				},
				error: function () {
					//called when there is an error
				},
			});
		} else {
			$('input[name="apartmentNo"]').val('');
			$('input[name="addressLine1"]').val('');
			$('input[name="addressLine2"]').val('');
			$('input[name="addressLine3"]').val('');
			$('input[name="addressLine4"]').val('');
			$('input[name="district"]').val('');
			$('input[name="city"]').val('');
			$('input[name="county"]').val('');
			$('input[name="postCode"]').val('');
			$('select[name="country"]').val('');
		}
	});

	/**
	 * LOGOUT CHECK
	 * trigger logout popup check
	 */
	$('#reveal-logout').on('click', '[data-trigger="logout-confirm"]', function (e) {
		e.preventDefault();
		window.location = '/logout';
	});
	// END LOGOUT CHECK

	$('body').on('change.zf.tabs', function(e) {
		let $tab = $(this).find('.tabs-title.is-active');
		let $a = $tab.find('a');
		if ($a.length) {
			let help = $a.data('help');
			if ((typeof help != 'undefined') && help.length) {
				$('button[data-open="reveal-help"]').data('identifier', help);
			}
		}
	});

	$('html, body').on('click', '[data-open="reveal-help"]', function (event) {
		event.stopPropagation();
		event.preventDefault();

		var $hid;

		var attr = $(this).attr('rel');

		if (typeof attr !== typeof undefined && attr !== false) {
			$hid = $(this).attr('rel');
		} else {
			$hid = $(this).data('identifier');
		}

		$('input[name="help-identifier"]').val($hid);

		$.ajax({
			url: "/help/getHelp/" + $hid,
			type: "GET",
			dataType: "html",
			success: function (html) {
				$('#help').html(html).show();
			}
		});
		$('#reveal-help').foundation('open');

	});

	$('#reveal-help').on('click', 'input[name="editHelp"]', function (event) {
		event.stopPropagation();

		let $hid = $('input[name="help-identifier"]').val();

		$.ajax({
			url: "/help/editHelp/" + $hid,
			type: "GET",
			dataType: "html",

			success: function (html) {
				$('#help').html(html).hide().fadeIn('slow');
			}
		});
	});

	$('#reveal-help').on('click', 'input[name="saveHelp"]', function (event) {
		event.stopPropagation();

		let $hid = $('input[name="help-identifier"]').val();
		let $html = $('textarea[name="helpHtml"]').val();

		$.ajax({
			url: '/help/saveHelp',
			type: 'POST',
			dataType: 'json',
			data: { hid: $hid, html: $html },

			beforeSend: function () {
				$('[data-help-error]').hide();
			},

			success: function (data) {
				if (!data.error) {
					$('#reveal-help').foundation('close');
				} else {
					logoutInactive(data);
					$('[data-help-error]').show();
				}
			}
		});
	});

	$('body').on('click tap', '#flush-all-keys, #flush-org-keys', function() {
		flushCache($(this).attr('id') == 'flush-all-keys');
	});

	$('body').on('click tap', '.token', function(event) {
		event.preventDefault();
		event.stopPropagation();
		let target = $(this).data('target');
		let token = $(this).data('token');
		if (target.length) {
			$('#' + target).insertAtCaret(token ? token : $(this).text()).keyup();
		}
	});

	$('body')
		.on('click tap', '.swatch', function(event) {
			event.preventDefault();
			event.stopPropagation();
			let picker = $(this).closest('.colour-picker');
			picker
				.data('selected', $(this).data('id'))
				.find('.swatch.picker-selected').removeClass('picker-selected')
				.empty();
			$(this)
				.html('<svg class="svg-inline--fa fa-lg fa-w-20"><use xlink:href="#fal-fa-circle-check" ></use></svg>')
				.addClass('picker-selected');
			picker.trigger('select.colour-picker', [$(this).data(), picker.data()]);
		})
		.on('change', 'input.colour-picker', function(event) {
			let colour = $(this).val();
			let picker = $(this).closest('.colour-picker');
			picker
				.data('selected', colour)
				.find('.swatch.picker-selected').removeClass('picker-selected');
			if (colour.length > 0) {
				picker.find('.swatch').each(function() {
					if ($(this).data('id') == colour) {
						$(this).click();
						return false;
					}
				});
			}
		});
}); // end ready

// Validates https://github.com/jackocnr/intl-tel-input inputs to ensure the telephone number is fully formed
function validateIntlTelInput($ele, required, parent) {
	if ($ele.val().length == 0) {
		return !required;
	}
	return $ele.intlTelInput('isValidNumber');
}

function ajaxValidator($ele, required, parent) {
	let url = window.location.pathname.split('/');
	let orgId = url[url.length - 1] || null;

	var validationUrl = parent.parents('form').data('validation-class');

	$.ajax({
		url: '/' + validationUrl + '/checkFormRules',
		type: 'POST',
		dataType: 'json',
		data: $($ele).serialize() + '&orgId=' + orgId,
	}).done(function (data, textStatus, xhr) {
		if (data.error == true) {
			updateValidationStatus($ele, parent, true, data.errorMsg);
		} else {
			updateValidationStatus($ele, parent, false, null);
		}
	}).fail(function (xhr, textStatus, errorThrown) {
		updateValidationStatus($ele, parent, true, 'Error. Please try again later.');
	});
}

function updateValidationStatus($ele, parent, validationStatus, validationMsg) {
	var parentForm = parent.parents('form');
	var button = parentForm.find('button[type="submit"]');
	var errorEle = $(parent).find('.form-error');

	if (validationStatus === true) {
		errorEle.text(validationMsg);
		parentForm.foundation('addErrorClasses', $ele);
	} else {
		parentForm.foundation('removeErrorClasses', $ele);
	}
}

function flushCache(all) {
	$.get({
		url: '/general/flushCache/' + (all ? 'all' : 'all-org'),
		dataType: 'json',
	})
	.done(function(data) {
		if (data.error) {
			let message;
			if (data.errors.length < 2) {
				message = data.errors.length ? data.errors[0] : 'An unknown error occurred';
			} else {
				message = '<p>The following errors occurred:</p><ul>';
				$.each(data.errors, function(e, error) {
					message += '<li>' + error +'</li>';
				})
				message += '</ul>';
			}
			$('#flush-cache-dialog').find('div.callout').html(message);
		} else {
			$('#flush-cache-dialog').foundation('close');
		}
	})
	.fail(function() {
		$('#flush-cache-dialog').find('div.callout').text('An unknown error occurred');
	});
}

function getNotes(table, tableId) {
	var loaded = $('body').data('loaded');
	$.ajax({
		url: '/general/getNotes',
		type: 'POST',
		dataType: 'html',
		data: { table, tableId, loaded },
		success: function (html, textStatus, xhr) {
			$('#notes-container').html(html).fadeIn('slow');
		},
		error: function (xhr, textStatus, errorThrown) {
			$('#notesErrors').html('Error: ' + errorThrown).fadeIn('slow');
		}
	});
}

function getVehicles(table, tableId) {
	$.ajax({
		url: '/general/getVehicles',
		type: 'POST',
		dataType: 'html',
		data: { table: table, tableId: tableId },

		beforeSend: function (xhr, textStatus) {
			//called when complete
		},
		success: function (html, textStatus, xhr) {
			$('.vehicleDetails').fadeIn('slow');
			$('#vehicles').html(html).fadeIn('slow');
		},
		error: function (xhr, textStatus, errorThrown) {
			//called when there is an error
			$('#errors').html('Error: ' + errorThrown).fadeIn('slow');
		}
	});
}

function deleteVehicle(id, table, tableId) {
	$.ajax({
		url: '/general/deleteVehicle',
		type: 'POST',
		dataType: 'json',
		data: { vehicleId: id, table: table, tableId: tableId },

		complete: function (xhr, textStatus) {
			//called when complete
		},
		success: function (data, textStatus, xhr) {
			if (data.error == true) {
				logoutInactive(data);
			}
			//called when successful
			getVehicles(table, tableId);
		},
		error: function (xhr, textStatus, errorThrown) {
			$('#errors').html('Error: ' + errorThrown).fadeIn('slow');
		}
	});
}

// watch for foundation abide form submit
$('html, body').on("submit", '[data-abide]', function (event) {
	// Do nothing so that the validation can be used
	event.preventDefault();
	event.stopPropagation();
});

String.prototype.hashCode = function() {
	var hash = 0, i, chr;
	if (this.length === 0) return hash;
	for (i = 0; i < this.length; i++) {
		chr   = this.charCodeAt(i);
		hash  = ((hash << 5) - hash) + chr;
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
};

String.prototype.nl2br = function() {
	return (this + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/escape
// doesn't seem available yet
String.prototype.escapeRegExp = function() {
    return (this + '').replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
window.fixedEncodeURIComponent = function(str) {
	return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
		return '%' + c.charCodeAt(0).toString(16);
	});
}

$.fn.extend({
	/**
	 * Used to insert text into a text field at the caret point.
	 * https://stackoverflow.com/questions/946534/insert-text-into-textarea-with-jquery
	 */
	insertAtCaret: function(myValue) {
		this.each(function() {
			if (document.selection) {
				this.focus();
				var sel = document.selection.createRange();
				sel.text = myValue;
				this.focus();
			} else if (this.selectionStart || this.selectionStart == '0') {
				var startPos = this.selectionStart;
				var endPos = this.selectionEnd;
				var scrollTop = this.scrollTop;
				this.value = this.value.substring(0, startPos)
					+ myValue
					+ this.value.substring(endPos,this.value.length);
				this.focus();
				this.selectionStart = startPos + myValue.length;
				this.selectionEnd = startPos + myValue.length;
				this.scrollTop = scrollTop;
			} else {
				this.value += myValue;
				this.focus();
			}
		});
		return this;
	},
	/**
	 * Store json in a script tag and read it using: let data = $('#id').inlineJson();
	 */
	inlineJson: function(flatten = true) {
		const data = [];
		this.each(function() {
			let contents = this.textContent
			try {
				data.push(JSON.parse(contents));
			} catch (e) {
				data.push(null);
			}
		});
		return data.length == 0 ? null : flatten && (data.length == 1) ? data[0] : data;
	},
	/**
	 * Apply a small delayed function call, e.g.: $(this).onTimeout(function(a, b) { console.log(a, b); }, 'foo', 'bar');
	 * Designed to call an ajax search after keypress stops for 1/2s. Clears its own interval so that action only runs once.
	 * $('#myfilter').on('keyup change', function() { $(this).onTimeout(dofilter, $(this).val(); });
	 */
	onTimeout: function(func) {
		let parameters = Array.prototype.slice.call(arguments, 1);
		this.each(function() {
			clearTimeout($(this).data('_onTimeout'));
			$(this).data('_onTimeout', setTimeout(func.bind(this, ...parameters), $.fn.onTimeout.delay));
		});
		return this;
	},
	/**
	 * Used to check whether an element has been initialised as a SumoSelect.
	 * e.g.: if ($(this).isSumo()) { ... }
	 */
	isSumo: function() {
		let sumod = $(this).length > 0;
		this.each(function() {
			sumod = sumod && ($(this)[0].sumo != undefined);
		});
		return sumod;
	},
	/**
	 * Adds some more methods to a SumoSelect.
	 * e.g.: const mysumo = $(this).SuperDuperSumo(); mysumo.showItem(i);
	 */
	SuperDuperSumo: function () {
		var sumo;
		this.each(function() {
			sumo = $(this)[0].sumo;
			if (sumo) {
				sumo.showItem = function (a) {
					this.toggleHide(false, a);
					this.enableItem(a);
				};
				sumo.hideItem = function (a) {
					this.toggleHide(true, a);
					this.disableItem(a);
				};
				sumo.toggleHide = function(c, i) {
					const O = this.vRange(i);
					if (!O.mob) O.optDiv.find('ul.options li.opt').eq(i).toggleClass('hide', c);
				};
			}
		});
		return sumo;
	},
	/**
	 * Used to remove a file from an file upload input.
	 * e.g.: $(this).removeFile(index)
	 */
	removeFile: function(index) {
		this.each(function() {
			const dataTransfer = new DataTransfer();
			for (let i = 0; i < this.files.length; i++) {
				if (index != i) {
					dataTransfer.items.add(this.files[i]);
				}
			}
			this.files = dataTransfer.files;
		});
		return this;
	},
});

$.fn.onTimeout.delay = 500;

// Use this namespace to set default parameters to be shared across scripts
window.zatsuite = {};

if (typeof moment != 'undefined') {
	window.zatsuite.daterangepicker = {
		"locale": {
			"format" : "Do MMMM YYYY",
			"separator": " - ",
			"firstDay": 1
		},
		"autoUpdateInput" : false,
		"startDate" : new Date(),
		"endDate" : new Date(),
		"maxDate" : new Date(),
		"opens" : "right",
		"drops" : "auto",
		"buttonClasses" : 'button small',
		"applyButtonClasses" : 'primary',
		"cancelButtonClasses" : 'clear'
	};
}

window.setDateRangePickerOptions = function()
{
	if (typeof moment != 'undefined') {
		window.zatsuite.daterangepicker.ranges = {};
		window.zatsuite.daterangepicker.ranges[i18n.__('daterangepicker.ranges.today')] = [moment(), moment()];
		window.zatsuite.daterangepicker.ranges[i18n.__('daterangepicker.ranges.yesterday')] = [
			moment().subtract(1, 'days'),
			moment().subtract(1, 'days')
		];
		window.zatsuite.daterangepicker.ranges[i18n.__n('daterangepicker.ranges.last', 7)] = [
			moment().subtract(6, 'days'),
			moment()
		];
		window.zatsuite.daterangepicker.ranges[i18n.__n('daterangepicker.ranges.last', 30)] = [
			moment().subtract(29, 'days'),
			moment()
		];
		window.zatsuite.daterangepicker.ranges[i18n.__('daterangepicker.ranges.month.current')] = [
			moment().startOf('month'),
			moment().endOf('month')
		];
		window.zatsuite.daterangepicker.ranges[i18n.__('daterangepicker.ranges.month.previous')] = [
			moment().subtract(1, 'month').startOf('month'),
			moment().subtract(1, 'month').endOf('month')
		];

		window.zatsuite.daterangepicker.locale = {
			"format" : "Do MMMM YYYY",
			"separator": " - ",
			"applyLabel": i18n.__('buttons.apply'),
			"cancelLabel": i18n.__('buttons.cancel'),
			"fromLabel": i18n.__('labels.from'),
			"toLabel": i18n.__('labels.to'),
			"customRangeLabel": i18n.__('daterangepicker.ranges.custom'),
			"weekLabel": "W",
			"daysOfWeek": [
				i18n.__('days.short.1'),
				i18n.__('days.short.2'),
				i18n.__('days.short.3'),
				i18n.__('days.short.4'),
				i18n.__('days.short.5'),
				i18n.__('days.short.6'),
				i18n.__('days.short.7')
			],
			"monthNames": [
				i18n.__('months.name.1'),
				i18n.__('months.name.2'),
				i18n.__('months.name.3'),
				i18n.__('months.name.4'),
				i18n.__('months.name.5'),
				i18n.__('months.name.6'),
				i18n.__('months.name.7'),
				i18n.__('months.name.8'),
				i18n.__('months.name.9'),
				i18n.__('months.name.10'),
				i18n.__('months.name.11'),
				i18n.__('months.name.12')
			],
			"firstDay": 1
		};
	}
}

window.getDateRangePickerDefaults = function ()
{
	setDateRangePickerOptions();
	return window.zatsuite.daterangepicker;
}

window.setFlatpickrOptions = function() {
	if (typeof 'flatpickr' != 'undefined') {
		window.zatsuite.flatpickr = {
			locale: {
				firstDayOfWeek: 1,
				weekdays: {
					shorthand: [
						i18n.__('days.short.1'),
						i18n.__('days.short.2'),
						i18n.__('days.short.3'),
						i18n.__('days.short.4'),
						i18n.__('days.short.5'),
						i18n.__('days.short.6'),
						i18n.__('days.short.7')
					],
					longhand: [
						i18n.__('days.name.1'),
						i18n.__('days.name.2'),
						i18n.__('days.name.3'),
						i18n.__('days.name.4'),
						i18n.__('days.name.5'),
						i18n.__('days.name.6'),
						i18n.__('days.name.7')
					],
				},
				months: {
					shorthand: [
						i18n.__('months.short.1'),
						i18n.__('months.short.2'),
						i18n.__('months.short.3'),
						i18n.__('months.short.4'),
						i18n.__('months.short.5'),
						i18n.__('months.short.6'),
						i18n.__('months.short.7'),
						i18n.__('months.short.8'),
						i18n.__('months.short.9'),
						i18n.__('months.short.10'),
						i18n.__('months.short.11'),
						i18n.__('months.short.12')
					],
					longhand: [
						i18n.__('months.name.1'),
						i18n.__('months.name.2'),
						i18n.__('months.name.3'),
						i18n.__('months.name.4'),
						i18n.__('months.name.5'),
						i18n.__('months.name.6'),
						i18n.__('months.name.7'),
						i18n.__('months.name.8'),
						i18n.__('months.name.9'),
						i18n.__('months.name.10'),
						i18n.__('months.name.11'),
						i18n.__('months.name.12')
					],
		  		},
				time_24hr: true,
			}
		};
	}
}

window.getFlatpickrDefaults = function ()
{
	setFlatpickrOptions();
	return window.zatsuite.flatpickr;
}